// Source:
// https://stackoverflow.com/questions/13246378/detecting-user-inactivity-over-a-browser-purely-through-javascript

export const initLogoutTimer = () => {
	var timerElem = $('#logouttimer .holder');

	if (!timerElem) {
		return;
	}

	var idleLimit = 60 * 5;
	var counter = 0;

	document.onclick = function() {
		counter = 0;
	};
	document.onmousemove = function() {
		counter = 0;
	};
	document.onkeypress = function() {
		counter = 0;
	};

	var checkInterval = window.setInterval(checkIdleTime, 1000);

	function checkIdleTime() {
		counter++;

		timerElem.html(idleLimit - counter);

		if (counter >= idleLimit) {
			clearInterval(checkInterval);

			var link = $('[data-id="logout"]').children().eq(0);
			link[0].click();
		}
	}

}

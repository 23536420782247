export const initLanguageSwitcher = () => {
	const languages = ['cs', '', 'en'];

	const languageSwitcher = $('#js-language-switcher');
	const languageSwitchTemplate = '%s%n%es';

	$.each(languages, (index, language) => {
		if (language === '') {
			createAndAppend('&nbsp;|&nbsp;');
		}

		let template = languageSwitchTemplate;
		let addStrong = OC.getLanguage() === language;

		template = template
			.replace('%s', addStrong ? '<strong>' : '')
			.replace('%es', addStrong ? '</strong>' : '');

		template = template.replace('%n', language.toUpperCase());

		createAndAppend(template);
	});

	$('#js-language-switcher div').on('click', (event) => {
		let clickedLang = event.currentTarget.innerText.toLowerCase();

		if (clickedLang.indexOf('|') !== -1 || OC.getLanguage() === clickedLang) {
			return;
		}

		updateLanguage(clickedLang);

		console.log(clickedLang);
	});

	function createAndAppend(template) {
		let div = document.createElement('div');
		div.innerHTML = template;

		$(div).appendTo(languageSwitcher);
	}

	function updateLanguage(selectedLang) {
		if (OC.PasswordConfirmation.requiresPasswordConfirmation()) {
			OC.PasswordConfirmation.requirePasswordConfirmation(updateLanguage);
			return;
		}

		var user = OC.getCurrentUser();

		$.ajax({
			url: OC.linkToOCS('cloud/users', 2) + user['uid'],
			method: 'PUT',
			data: {
				key: 'language',
				value: selectedLang
			},
			success: function() {
				location.reload();
			},
			fail: function() {
				OC.Notification.showTemporary(t('settings', 'An error occurred while changing your language. Please reload the page and try again.'));
			}
		});
	}

}
